import React from 'react';
import {
  block as blockApiFunc,
  useReactMutation,
  useTranslation,
  routeNames,
} from '@lobox/utils';
import Typography from '@lobox/uikit/Typography';
import Button from '@lobox/uikit/Button';
import useToast from '@lobox/uikit/Toast/useToast';
import { useHistory } from '@lobox/utils';
import type { EntityType } from '@lobox/utils';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

type BlockProps = {
  entityId: string;
  entityType: EntityType;
  title?: string;
  name?: string;
};

type UseBlockType = Omit<UseMutationResult, 'mutate'> & {
  block: (props: BlockProps, opt?: UseMutationOptions) => void;
};

const useBlock = (): UseBlockType => {
  const { mutate, ...rest } = useReactMutation({
    apiFunc: blockApiFunc,
  });
  const toast = useToast();
  const { t } = useTranslation();
  const history = useHistory();

  const viewSettingsPrivacy = () => {
    history.push(routeNames.settingsPrivacy);
  };
  const block = (
    { entityId, entityType, title = '', name = '' }: BlockProps,
    options?: UseMutationOptions
  ) => {
    mutate(
      {
        entityId,
        entityType,
      }, // @ts-ignore
      {
        ...options,
        onSuccess: (data, variables, context) => {
          // @ts-ignore
          options?.onSuccess(data, variables, context);

          if (title && name) {
            toast({
              type: 'success',
              icon: 'check-circle',
              title: `${title} ${t('blocked')}`,
              message: () => (
                <>
                  <Typography font="bold" mr={3}>
                    {name}
                  </Typography>
                  <Typography>
                    {t('has_been_blocked')}. {t('i_y_t_t_mistake_g_s')}
                  </Typography>
                </>
              ),
              actionButton: () => (
                <Button
                  onClick={viewSettingsPrivacy}
                  schema="ghost-brand"
                  label={t('view_settings')}
                  variant="text"
                />
              ),
            });
          }
        },
      }
    );
  };

  return {
    block,
    ...rest,
  };
};

export default useBlock;
