import classes from './index.module.scss';

import React from 'react';
import isString from 'lodash/isString';
import cnj from '../utils/cnj';
import type { TypographyProps } from '../Typography';
import Typography from '../Typography';
import Flex from '../Flex';

interface PaperProps {
  title?: string;
  subTitle?: React.ReactNode;
  children: React.ReactNode | React.ReactElement;
  className?: any;
  titleContainerClassName?: string;
  contentClassName?: string;
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse';
  hoverColor?: string;
  hasTitleBorder?: boolean;
  noHover?: boolean;
  titleClassName?: string;
  subTitleClassName?: string;
  titleProps?: Omit<TypographyProps, 'children'>;
  subtitleProps?: Omit<TypographyProps, 'children'>;
}

const Paper = ({
  title,
  subTitle,
  children,
  className,
  contentClassName,
  titleContainerClassName,
  titleClassName,
  direction = 'column',
  hoverColor,
  hasTitleBorder,
  subTitleClassName,
  noHover,
  titleProps,
  subtitleProps = {},
}: PaperProps): JSX.Element => {
  const isRow = direction === 'row';
  const isRowReverse = direction === 'row-reverse';
  const isColumnReverse = direction === 'column-reverse';

  return (
    <Flex className={cnj(classes.paperRoot, className)}>
      <Flex
        className={cnj(
          classes.paperContent,
          classes[`hover-backgroundColor-${hoverColor}`],
          isRow && classes.flexDirRow,
          isRowReverse && classes.flexDirRowReverse,
          isColumnReverse && classes.flexColRowReverse,
          noHover && classes.noHover,
          contentClassName
        )}
      >
        {(title || subTitle) && (
          <Flex
            className={cnj(
              classes.titleWrap,
              hasTitleBorder && classes.titleBorderBottom,
              isRow && classes.titleWrapRow,
              isColumnReverse && classes.titleWrapColumnReverse,
              isRowReverse && classes.titleWrapRowReverse,
              titleContainerClassName
            )}
          >
            <Typography
              color="thirdText"
              size={16}
              textAlign={isColumnReverse ? 'center' : 'left'}
              font="bold"
              className={cnj(classes.width, titleClassName)}
              height={21}
              isTruncated
              {...titleProps}
            >
              {title}
            </Typography>
            {subTitle && isString(subTitle) && (
              <Typography
                className={cnj(classes.width, subTitleClassName)}
                mt={6}
                size={15}
                color="primaryDisabledText"
                height={21}
                isTruncated
                {...subtitleProps}
              >
                {subTitle}
              </Typography>
            )}
            {subTitle && !isString(subTitle) && subTitle}
          </Flex>
        )}
        {children}
      </Flex>
    </Flex>
  );
};

export default Paper;
